// // Education.js
// import React, { useRef, useEffect } from 'react';
// import styled from 'styled-components';
// import { srConfig } from '@config';
// import sr from '@utils/sr';
// import { usePrefersReducedMotion } from '@hooks';
// import vt from '../../images/vt.png';
// import booth from '../../images/uchicago.png';

// // Outer container styling
// const StyledEducationSection = styled.section`
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 80px 0;

//   h2 {
//     text-align: center;
//     margin-bottom: 50px;
//     font-size: var(--fz-heading);
//   }

// `;

// /**
//  * The timeline is placed within this container.
//  * The vertical line is drawn using &:before,
//  * which we position to the RIGHT of the icon.
//  */
// const Timeline = styled.div`
//   position: relative;
//   margin-left: 80px; 
//   /* This margin creates space so the icon can be on the left 
//      and the line is still on the parent's inside. Adjust as you like. */

//   &:before {
//     content: "";
//     position: absolute;
//     left: 32px; 
//     /* This is where the vertical line is anchored. 
//        Because the icon is 50px wide and is placed around -60px, 
//        32px is ~ right next to it. Tweak to your taste. */
//     top: 0;
//     bottom: 0;
//     width: 2px;
//     background: var(--lightest-navy);
//   }
// `;

// const TimelineItem = styled.div`
//   position: relative;
//   margin: 20px 0;

//   /* The circular icon with the school logo */
//   .icon {
//     position: absolute;
//     left: -60px; 
//     width: 100%;
//     height: 100px;
//     border: 2px solid var(--green);
//     border-radius: 1%;
//     overflow: hidden;
//     margin-top: 0.5rem;
//     background-color: var(--navy);

//     img {
//       width: 100%;
//       height: 100%;
//       object-fit: cover; 
//     }
//   }

//   /* The content bubble is offset to the right, leaving space for the line. */
//   .content {
//     margin-left: 40px; 
//     /* 40px from where the line is. Enough so it doesn't overlap. */
//     background: var(--light-navy);
//     border-radius: 6px;
//     box-shadow: 0 10px 30px -15px var(--navy-shadow);
//     padding: 1rem 1.5rem;

//     h3 {
//       margin: 0;
//       font-size: var(--fz-lg);
//       color: var(--lightest-slate);
//     }

//     .subheading {
//       font-size: var(--fz-md);
//       color: var(--green);
//       margin: 0.3rem 0;
//       font-weight: 500;
//     }

//     .range {
//       font-size: var(--fz-sm);
//       font-style: italic;
//       color: var(--light-slate);
//       margin-bottom: 0.5rem;
//       display: block;
//     }

//     p {
//       margin: 0.5rem 0 0;
//       font-size: var(--fz-sm);
//       color: var(--light-slate);
//     }
//   }
// `;

// const Education = () => {
//   const revealContainer = useRef(null);
//   const prefersReducedMotion = usePrefersReducedMotion();

//   useEffect(() => {
//     if (!prefersReducedMotion) {
//       sr.reveal(revealContainer.current, srConfig());
//     }
//   }, [prefersReducedMotion]);

//   // Example data. Replace with your real image paths or URLs.
//   const educations = [
//     {
//       institution: 'Booth School of Business - Univ. of Chicago',
//       subheading: 'Summer Business Scholar',
//       dateRange: 'Summer 2021',
//       location: 'Chicago, IL',
//       description:
//         'Participated in the Summer Business Scholars program, focusing on core business principles and leadership development.',
//       image: booth,
//     },
//     {
//       institution: 'Virginia Tech',
//       subheading: 'Master of Engineering, Computer Science (AI)',
//       dateRange: 'Expected Dec 2024',
//       location: 'Blacksburg, VA',
//       description:
//         'GPA: 3.91 / 4.00. Specializing in AI with advanced coursework in ML, Deep Learning, and Cloud Computing.',
//       image: vt,
//     },
//     {
//       institution: 'Virginia Tech',
//       subheading: 'Bachelor of Science, Computer Science',
//       dateRange: '2019 – 2023',
//       location: 'Blacksburg, VA',
//       description:
//         'President’s List: Fall 2020, Spring 2021. Strong fundamentals in software engineering, data science, and AI.',
//       image: vt,
//     },
//   ];

//   return (
//     <StyledEducationSection id="education" ref={revealContainer}>
//       <h2 className="numbered-heading">Education</h2>

//       <Timeline>
//         {educations.map((edu, idx) => (
//           <TimelineItem key={idx}>
//             <div className="icon">
//               <img src={edu.image} alt={`${edu.institution} logo`} />
//             </div>
//             <div className="content">
//               <h3>{edu.institution}</h3>
//               <div className="subheading">{edu.subheading}</div>
//               <span className="range">
//                 {edu.dateRange} &middot; {edu.location}
//               </span>
//               <p>{edu.description}</p>
//             </div>
//           </TimelineItem>
//         ))}
//       </Timeline>
//     </StyledEducationSection>
//   );
// };

// export default Education;

// Education.js
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';
import vt from '../../images/vt.png';
import booth from '../../images/uchicago.png';

const StyledEducationSection = styled.section`
  max-width: 950px;
  margin: 0px auto;
  padding: 80px 0;
  h2 {
    text-align: center;
    margin-bottom: 50px;
    font-size: var(--fz-heading);
  }
`;

const Timeline = styled.div`
  position: relative;
  margin-left: 90px;

  &:before {
    content: "";
    position: absolute;
    left: 42px;
    top: 0;
    bottom: 0;
    width: 2px;
    background: var(--lightest-navy);
  }
`;

const TimelineItem = styled.div`
  position: relative;
  margin: 20px 0;

  .icon {
    position: absolute;
    left: -80px;
    width: 120px;
    height: 120px;
    border: 3px solid var(--green);
    border-radius: 30%;
    overflow: hidden;
    margin-top: 0.5rem;
    background-color: var(--white);

    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
      }

    img {
      width: 90%;
      height: auto;
      object-fit: contain;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
  }

  .content {
    margin-left: 50px;
    width:90%;
    background: var(--light-navy);
    border-radius: 6px;
    box-shadow: 0 10px 30px -15px var(--navy-shadow);
    padding: 1rem 1.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 12px 35px -15px var(--navy-shadow);
    }

    h3 {
      margin: 0;
      font-size: var(--fz-lg);
      color: var(--white);
    }

    .subheading {
      font-size: var(--fz-md);
      color: var(--white);
      margin: 0.3rem 0;
      font-weight: 500;
    }

    .range {
      font-size: var(--fz-sm);
      font-style: italic;
      color: var(--grey);
      margin-bottom: 0.5rem;
      display: block;
    }

    p {
      margin: 0.5rem 0 0;
      font-size: var(--fz-sm);
      color: var(--grey);
    }
  }
`;

const Education = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (!prefersReducedMotion) {
      sr.reveal(revealContainer.current, srConfig());
    }
  }, [prefersReducedMotion]);

  const educations = [
    {
      institution: 'Virginia Tech',
      subheading: 'Master of Engineering, Computer Science (AI)',
      dateRange: 'Graduated Dec 2024',
      location: 'Blacksburg, VA',
      description:
        'GPA: 3.91 / 4.00. Specializing in AI with advanced coursework in ML, GenAI, and Software Development.',
      image: vt,
    },
    {
      institution: 'Virginia Tech',
      subheading: 'Bachelor of Science, Computer Science',
      dateRange: '2019 – 2023',
      location: 'Blacksburg, VA',
      description:
        'GPA: 3.58 / 4.00. 2x President’s List, 6x Dean’s List. Core focus on software engineering, data science, and AI.',
      image: vt,
    },
    {
      institution: 'Booth School of Business - Univ. of Chicago',
      subheading: 'Summer Business Scholar (Selected Student & Scholarship Recipient)',
      dateRange: 'Summer 2021',
      location: 'Chicago, IL',
      description:
        'Participated in the Summer Business Scholars program, focusing on core business principles and leadership development.',
      image: booth,
    },
  ];

  return (
    <StyledEducationSection id="education" ref={revealContainer}>
      <h2 className="numbered-heading">Education</h2>
      <Timeline>
        {educations.map((edu, idx) => (
          <TimelineItem key={idx}>
            <div className="icon">
              <img src={edu.image} alt={`${edu.institution} logo`} />
            </div>
            <div className="content">
              <h3>{edu.institution}</h3>
              <div className="subheading">{edu.subheading}</div>
              <span className="range">
                {edu.dateRange} &middot; {edu.location}
              </span>
              <p>{edu.description}</p>
            </div>
          </TimelineItem>
        ))}
      </Timeline>
    </StyledEducationSection>
  );
};

export default Education;