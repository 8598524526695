import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';
import vt from '../../images/vtbur.jpeg';
import cgvt from '../../images/consulting_group.jpeg';

const StyledLeadershipSection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;

  h2 {
    font-size: var(--fz-heading);
    margin-bottom: 60px;
    text-align: center;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
  }
`;

const LeadershipCard = styled.div`
  position: relative;
  background: var(--light-navy);
  border-radius: 12px;
  box-shadow: 0 10px 30px -15px var(--navy-shadow);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px -15px var(--navy-shadow);
  }

  .image {
    width: 100%;
    height: 180px;
    background: var(--green);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }

  .content {
    padding: 20px;

    h3 {
      font-size: var(--fz-lg);
      margin: 0 0 10px;
      color: var(--white);
    }

    .subheading {
      font-size: var(--fz-md);
      font-weight: 500;
      color: var(--light-grey);
      margin-bottom: 10px;
    }

    .range {
      font-size: var(--fz-sm);
      font-style: italic;
      color: var(--grey);
    }
  }
`;

const Leadership = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (!prefersReducedMotion) {
      sr.reveal(revealContainer.current, srConfig());
    }
  }, [prefersReducedMotion]);

  const leadershipRoles = [
    {
      subheading: 'Software Development Club at Virginia Tech',
      dateRange: '2023 - 2024',
      image: vt,
      title: 'Founder and President',
    },
    {
      subheading: 'Consulting Group at Virginia Tech',
      dateRange: '2021',
      image: cgvt,
      title: 'Associate',
    }
  ];

  return (
    <StyledLeadershipSection id="leadership" ref={revealContainer}>
      <h2 className="numbered-heading">Leadership And Clubs</h2>
      <div className="grid">
        {leadershipRoles.map((role, idx) => (
          <LeadershipCard key={idx}>
            <div className="image">
              <img src={role.image} alt={`${role.title} logo`} />
            </div>
            <div className="content">
              <h3>{role.title}</h3>
              <div className="subheading">{role.subheading}</div>
              <div className="range">{role.dateRange}</div>
            </div>
            
          </LeadershipCard>
          
        ))}
      </div>
    </StyledLeadershipSection>
  );
};

export default Leadership;
