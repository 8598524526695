import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const StyledLeetCodeSection = styled.section`
  max-width: 800px;
  margin: 50px auto;
  padding: 24px;
  background-color: var(--light-navy);
  border: 1px solid #ddd;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  margin-bottom: 150px;

  h3 {
    color: var(--grey);
    font-family: 'Space Mono', monospace;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 0;

    li {
    ${({ theme }) => theme.mixins.bigButton};
      color: #333;
      margin-top: 16px;
      position: relative;
      font-size: 1rem;
      line-height: 1.4;
      font-family: 'Space Mono', monospace;
      background-color: white;
      border-radius: var(--border-radius);
      padding: 16px 16px 16px 60px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
      list-style: none;
      cursor: pointer;

      &:hover {
        transform: translateY(-3px);
        background-color: #d3d3d3;
      }

      &:before {
        content: '';
        display: block;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        background-color: #ddd;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }

      &.easy:before {
        background-color: #27ae60;
      }

      &.medium:before {
        background-color: #f1c40f;
      }

      &.hard:before {
        background-color: #e74c3c;
      }

      a {
        text-decoration: none;
        color: #0073e6;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }

      strong {
        color: #333;
      }

      p {
        margin: 4px 0;
      }
    }
  }
`;

const LeetCodeSubmissions = () => {
  const [leetcodeSubmissions, setLeetcodeSubmissions] = useState([]);

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await axios.get('https://alfa-leetcode-api.onrender.com/roshanrav/acSubmission?limit=3');
        if (response.data && response.data.submission) {
          const submissions = response.data.submission;

          const detailedSubmissions = await Promise.all(
            submissions.map(async submission => {
              const detailResponse = await axios.get(
                `https://alfa-leetcode-api.onrender.com/select?titleSlug=${submission.titleSlug}`
              );
              return {
                ...submission,
                difficulty: detailResponse.data.difficulty,
                problemLink: `https://leetcode.com/problems/${submission.titleSlug}`
              };
            })
          );

          setLeetcodeSubmissions(detailedSubmissions);
        } else {
          console.error('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching LeetCode submissions:', error);
      }
    };

    fetchSubmissions();
  }, []);

  const getDifficultyClass = difficulty => {
    switch (difficulty) {
      case 'Easy':
        return 'easy';
      case 'Medium':
        return 'medium';
      case 'Hard':
        return 'hard';
      default:
        return '';
    }
  };

  return (
    <StyledLeetCodeSection>
      <h3>Recent LeetCode Accepted Submissions</h3>
      <ul>
        {leetcodeSubmissions.length > 0 ? (
          leetcodeSubmissions.map((submission, index) => (
            <li
              key={index}
              className={getDifficultyClass(submission.difficulty)}
              onClick={() => window.open(submission.problemLink, '_blank')}
            >
              <a href={submission.problemLink} target="_blank" rel="noreferrer">
                <strong>{submission.title}</strong>
              </a>
              <p><strong>Language:</strong> {submission.lang}</p>
              <p><strong>Difficulty:</strong> {submission.difficulty}</p>
            </li>
          ))
        ) : (
          <li>Loading recent submissions...</li>
        )}
      </ul>
    </StyledLeetCodeSection>
  );
};

export default LeetCodeSubmissions;
